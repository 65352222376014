<template>
  <div class="container min-width-card p-0 m-0">
    <div class="row full-heigth w-100 p-0 m-0">
      <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage">
      </loading>

      <div class="card card-fullheight m-0 pb-2 w-100">
        <div class="card-body">
          <button type="button" class="royalc-btn-close pt-2 pr-0 float-right" @click="onClose()"
            aria-label="Close modal"><i class="fas fa-times"></i></button>
          <h5 class="box-title my-0 pb-0 pt-2"><small>Curtida em {{ typeName }}</small></h5>

          <hr>

          <div class="row px-0 mx-0">
            <!-- Texto -->
            <div class="col-12 text-left form-group px-2 mb-0">
              <label class="small grey-label mb-0">Comentário</label>
              <custom-textarea
                v-model="like.texto"
                name="like.texto"
                type="text"
                rootClassName="md-form my-0"
                inputClassName="md-form-control my-0"
                :rows="6"
                v-validate="{ required: false }"
                :error="errors.first('like.texto')">
              </custom-textarea>
            </div>
          </div>

          <hr>

          <!-- Botões abaixo -->
          <div class="row p-0 m-0">
            <div class="col-12 align-center">
              <!-- <button class="btn btn-link btn-sm btn-a-color mt-1"
                @click="mostrar()">Console</button> -->
              <button class="btn btn-link btn-sm btn-a-color mt-1"
                @click="onClose()">Cancelar</button>
              <button class="btn btn-sm btn-outline-light btn-a-color m-1"
                @click="onSave()">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Form
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

// Services
import ValuationService from '@/services/ValuationService'

export default {
  name: 'ValuationModeration',
  components: {
    Loading,
    CustomTextarea
  },
  data () {
    return {
      isLoading: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      subsLoaded: false,
      like: {
        texto: null
      }
    }
  },
  created () {
    let _this = this

    if (this.id) {
      ValuationService.getComment(this.id).then(res => {
        this.like = res.data.data
        this.subsLoaded = true
      })
    }

    _this.isLoading = false
  },
  beforeMount () {
    // console.log('beforeMount user: ', this.user)
    // console.log('beforeMount id: ', this.id)
  },
  mounted () {
    // console.log('router: ', this.$router)
    // console.log('valuation: ', this)
    // console.log('mounted user: ', this.user)
    // console.log('mounted id: ', this.id)
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    // mountLink (itemName, object) {
    //   return this.$router.resolve({ name: itemName, params: { id_Valuation: object.id } }).href
    // },
    mostrar () {
      // console.log('this.valuation: ', {
      //   like: this.like,
      //   id: this.id,
      //   valuation: this.valuation
      // })
    },
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    },
    onSave () {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let comment = {
            avaliacao: this.valuation.id,
            texto: this.like.texto
          }

          if (this.id) {
            ValuationService.putComment(this.id, comment).then(response => {
              this.$emit('refresh')
              this.onClose()
              _this.isLoading = false
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            ValuationService.postComment(comment).then(response => {
              this.$emit('refresh')
              this.onClose()
              _this.isLoading = false
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          }
        }
      })
    }
  },
  computed: {
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isTeamAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_VALUATION_MANAGER') !== -1) {
        return true
      }
      return false
    },
    typeName () {
      let name = 'Avaliação'

      if (!this.valuation.motivo) {
        return name
      }

      if (this.valuation.motivo.tipo.nome) {
        return this.valuation.motivo.tipo.nome
      } else if (this.valuation.motivo.tipo) {
        return this.valuation.motivo.tipo
      }

      return name
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.btn-a-color {
  color: #534B41 !important;
}

@media (min-width: 768px) {
    .min-width-card {
        min-width: 440px;
    }
}

@media (min-width: 992px) {
    .min-width-card {
        min-width: 660px;
    }
}
</style>
