<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
      <div class="row justify-content-md-center pt-2 px-2">

        <!-- Main Card -->
        <div class="col-12">
          <div class="card mb-2">
            <div class="card-body">

              <!--  Header and Actions -->
              <div class="flexbox">
                <div class="d-flex align-items-center">
                  <h5 class="box-title mb-3">Registro de {{ valuation.motivo ? valuation.motivo.tipo.nome : 'avaliação' }}</h5>
                </div>
                <div class="btn-group" role="group" aria-label="false">
                  <!-- <button type="button" class="btn btn-sm btn-outline-light pull-right ml-2"
                    @click="onShow">Console</button> -->
                  <button v-if="!liked" type="button" class="btn btn-sm btn-outline-light pull-right ml-2"
                    @click="onLike" :disabled="!valuation.motivo"><span class="ti-thumb-up"></span> Curtir</button>
                  <button v-else type="button" class="btn btn-sm btn-outline-light pull-right ml-2 text-grey"
                    @click="onUnlike" :disabled="!valuation.motivo"><span class="ti-thumb-up"></span> Curtiu</button>
                </div>
              </div>

              <hr class="px-0 pt-0 mx-0 mt-0">

              <!-- Alto -->
              <div class="row custom-row">

                <!-- Esquerda -->
                <div class="col-md-6 px-2 pb-3 text-left">
                  <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                      <small>Pessoas envolvidas</small>
                      <div v-for="(alvo, index) in valuation.alvos" v-bind:key="index" class="font-weight-bold pl-2">
                        {{ alvo.alvo.id_pessoa.nome }}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                      <small>Descrição</small>
                      <div class="font-weight-bold pl-2">
                        {{ $util.formatEmpty(valuation.texto) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Direita -->
                <div class="col-md-6 px-2 pb-3 text-left">
                  <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                      <small>Origem</small>
                      <div class="font-weight-bold pl-2">
                        {{ $util.formatEmpty(valuation.origem ? valuation.origem.nome : null) }}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                      <small>Informante</small>
                      <div class="font-weight-bold pl-2">
                        {{ $util.formatEmpty(valuation.informante ? valuation.informante.nome : null) }}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                      <small>Motivo</small>
                      <div class="font-weight-bold pl-2">
                        {{ $util.formatEmpty(valuation.motivo ? valuation.motivo.nome : null) }}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-md-6 p-0 m-0">
                      <small>Referência interna</small>
                      <div class="font-weight-bold pl-2">
                        {{ $util.formatEmpty(valuation.referencia_interna) }}
                      </div>
                    </div>

                    <div class="col-md-6 p-0 m-0">
                      <label v-if="!valuation.motivo || !valuation.valor" class="small grey-label mb-0">Economia/Prejuízo</label>
                      <label v-else-if="valuation.motivo.tipo.positivo" class="small grey-label mb-0">Economia</label>
                      <label v-else class="small grey-label mb-0">Prejuízo</label>
                      <div class="font-weight-bold pl-2">
                        {{ $util.formatToPrice(valuation.valor) }}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-md-6 p-0 m-0">
                      <small>Situação</small>
                      <div class="font-weight-bold pl-2">
                        <span :style="{ color: formatStatusColor(valuation.situacao) }">{{ formatStatusName(valuation.situacao) }}</span>
                      </div>
                    </div>

                    <div class="col-md-6 p-0 m-0">
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="row custom-row mx-0">
                <div v-if="valuation.arquivos && valuation.arquivos.length" class="col-12 px-0 mb-3">
                  <small>Anexos</small>
                  <div>
                    <button v-for="(anexo, index) in valuation.arquivos" v-bind:key="index" type="button" class="btn btn-link btn-sm"
                      @click="onDownload(anexo.file)">{{ anexo.file.original_name }}</button>
                    <!-- <a class="btn btn-link"
                        v-on:click="onDelete(props.rowData)">
                      <i class="text-primary  ti-trash font-20"></i>
                    </a> -->
                  </div>
                </div>
                <div v-else class="col-12 px-0 mb-3">
                  <small class="font-italic">Sem anexos</small>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row justify-content-md-center pt-2 px-2">
        <!-- Comentários e Curtidas -->
        <div class="col-md-6">
          <div class="card card-fullheight">
            <div class="card-body">

              <!--  Header and Actions -->
              <div class="flexbox">
                <div class="d-flex align-items-center">
                  <h5 class="box-title mb-3">Comentários ({{ commentsCount }})</h5>
                </div>
              </div>

              <hr class="px-0 pt-0 mx-0 mt-0">

              <!-- Comentários -->
              <div class="row custom-row">
                <div class="col-12 px-2 pb-3 text-left">
                  <div v-for="(curtida, index) in valuation.curtidas" v-bind:key="index" class="row custom-row">
                    <div v-if="curtida.texto">
                      <small>{{ curtida.autor.name }} (<small class="font-italic">{{ $util.formatDate(curtida.data_criacao) }}</small>):</small><span class="font-italic"> {{ curtida.texto }}</span>
                    </div>
                  </div>
                  <div v-if="!commentsCount" class="row custom-row">
                    <div>
                      <small class="font-italic">Sem registros de comentários</small>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="px-0 pt-0 mx-0 mt-0">

              <!-- Curtidas -->
              <div class="row custom-row mx-0">
                <div v-if="valuation.curtidas.length" class="col-12 px-0 mx-0 pb-3 text-left">
                  <small class="font-weight-bold">Curtidas ({{ valuation.curtidas.length }}): </small>
                  <small v-for="(curtida, index) in valuation.curtidas" v-bind:key="index">{{ curtida.autor.name }}<span v-if="index != valuation.curtidas.length - 1">, </span></small>
                </div>
                <div v-else class="col-12 px-0 mx-0 pb-3 text-left">
                  <small class="font-italic">Sem curtidas</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Histórico de moderação -->
        <div class="col-md-6">
          <div class="card card-fullheight">
            <div class="card-body">

              <!--  Header and Actions -->
              <div class="flexbox">
                <div class="d-flex align-items-center">
                  <h5 class="box-title mb-3">Histórico de moderação ({{ moderationsCount }})</h5>
                </div>
              </div>

              <hr class="px-0 pt-0 mx-0 mt-0">

              <!-- Histórico -->
              <div class="row custom-row">
                <div class="col-12 px-2 pb-3 text-left">
                  <div v-for="(moderacao, index) in valuation.moderacoes" v-bind:key="index" class="row custom-row">
                    <div>
                      <small>{{ moderacao.moderador.id_pessoa.nome }} (<small
                        class="font-italic">{{ $util.formatDate(moderacao.data_criacao) }}</small>) <span
                          :style="{ color: formatStatusColor(moderacao.resultado) }">{{ formatStatusName(moderacao.resultado) }}</span>
                        <span v-if="moderacao.texto">:</span></small>
                        <span v-if="moderacao.texto" class="font-italic"> {{ moderacao.texto }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Vue from 'vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services
import ValuationService from '@/services/ValuationService'

import CommentModal from '@/views/Valuation/LikeForm.vue'

export default {
  name: 'ValuationDetailView',
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      subsLoaded: false,
      valuation: {
        motivo: null,
        origem: null,
        descricao: null,
        observacao: null,
        referencia_interna: null,
        valor: null,
        peso: null,
        situacao: 0,
        dataCriacao: null,
        arquivos: [],
        alvos: [],
        curtidas: []
      },
      statusList: [
        {
          name: 'Informado',
          color: '#f1ba26' // amarelo
        },
        {
          name: 'Aprovado',
          color: '#5ec65f' // verde
        },
        {
          name: 'Recusado',
          color: '#ff0000' // vermelho
        }
      ]
    }
  },
  created () {
    let _this = this
    let id = this.$route.params.id

    ValuationService.get(id).then(res => {
      this.valuation = res.data.data
    })

    _this.isLoading = false
  },
  beforeMount () {
  },
  mounted () {
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    onShow () {
      // console.log('files: ', this.valuation)
    },
    onfocus () {
      // console.log('this.valuation: ', this.valuation)
    },
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    },
    onLike (data) {
      let me = this
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(CommentModal)
        var modalsInstance = new ComponentClass({
          data: {
            user: me.user,
            id: data ? data.id : null,
            valuation: this.valuation
          }
        })
        modalsInstance.$mount()
        modalsInstance.$on('refresh', this.refreshPage)
        let detailsContent = modalsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsContent, null,
          'no-padding w-auto', { showConfirmButton: false }).then((result) => { })
      }
    },
    onUnlike () {
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir a curtida? <br> Isso também apagará seu comentário',
        'warning', 'Sim', 'Não', (e) => this.unlike()).then((result) => {
        this.refreshPage()
      })
    },
    async unlike () {
      let _this = this
      _this.isLoading = true
      await ValuationService.deleteLike(this.valuation.id).then(res => {
        if (res.httpCode === 200) {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    refreshPage () {
      let id = this.$route.params.id
      ValuationService.get(id).then(res => {
        this.valuation = res.data.data
      })
    },
    onDownload (file) {
      this.isLoading = true
      ValuationService.getFile(file.id).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', file.original_name)
        global.document.body.appendChild(link)
        link.click()
      }).finally(() => {
        this.isLoading = false
      })
    },
    formatStatusColor (value) {
      if (value === null || value === undefined) {
        return ' - '
      }

      return this.statusList[value].color
    },
    formatStatusName (value) {
      if (value === null || value === undefined) {
        return ' - '
      }

      return this.statusList[value].name
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    liked () {
      if (!this.user) {
        return false
      }

      let userId = this.user.id

      if (!this.valuation.curtidas) {
        return false
      }

      let hasLike = this.valuation.curtidas.filter((x) => {
        return x.autor.id === userId
      })

      return hasLike.length > 0
    },
    commentsCount () {
      if (!this.valuation.curtidas) {
        return 0
      }

      return this.valuation.curtidas.filter((x) => { return x.texto }).length
    },
    moderationsCount () {
      if (!this.valuation.moderacoes) {
        return 0
      }
      return this.valuation.moderacoes.length
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.btn-a-color {
  color: #534B41 !important;
}

@media (min-width: 768px) {
    .min-width-card {
        min-width: 640px;
    }
}

@media (min-width: 992px) {
    .min-width-card {
        min-width: 860px;
    }
}
</style>
